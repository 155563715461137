<template>
  <div class="dashboard">
    <!-- cont_top -->
    <div class="cont_top">
      <!-- count_top_box -->
      <div class="count_top_box">
        <div class="app_dnld_box">
          <h2 class="count_title">지자체 회원가입 수</h2>
          <p class="count_text">{{ $n(totalClientCnt.all) }}명</p>
          <!-- <span class="android">안드로이드</span>
          <span class="android_count">{{ $n(totalClientCnt.android) }}건</span>
          <span class="ios ml_25">ios</span
          ><span class="ios_count">{{ $n(totalClientCnt.ios) }}건</span> -->
          <span class="android_count"></span>
          <div class="round_box">
            <font-awesome-icon :icon="['fa', 'users']" class="fontawe green" />
          </div>
        </div>
        <div class="member_box_02">
          <h2 class="count_title">일반 회원가입 수</h2>
          <p class="count_text">{{ $n(totalUserCnt.all) }}명</p>
          <span class="android">안드로이드</span>
          <span class="android_count">{{ $n(totalUserCnt.android) }}명</span>
          <span class="ios ml_25">ios</span
          ><span class="ios_count">{{ $n(totalUserCnt.ios) }}명</span>
          <div class="round_box">
            <font-awesome-icon :icon="['fa', 'user']" class="fontawe blue" />
          </div>
        </div>
      </div>
      <!-- //count_top_box -->
      <!-- <span class="now_dt_base">2020.07.16 14:08 현재</span> -->
    </div>
    <!-- //cont_top -->
    <div class="tbl_middle_box">
      <h3 class="tbl_count_title">
        전 일 문제신고 관리 :<router-link
          :to="{ name: 'Report' }"
          class="count_link"
          >{{ report.totalItems }}</router-link
        >건
      </h3>
      <base-table
        :loading="false"
        :isEdit="false"
        :isSort="false"
        :pageSize="pageSize"
        :fields="report.fields"
        :items="report.items"
      />
    </div>
    <div class="tbl_middle_box">
      <h3 class="tbl_count_title">
        전 일 입점&제휴문의 :<router-link
          :to="{ name: 'AffiliateInquiry' }"
          class="count_link"
          >{{ inquiry.totalItems }}</router-link
        >건
      </h3>
      <base-table
        :loading="false"
        :isEdit="false"
        :isSort="false"
        :pageSize="pageSize"
        :fields="inquiry.fields"
        :items="inquiry.items"
      />
    </div>
    <div class="tbl_middle_box">
      <h3 class="tbl_count_title">
        전 일 페스티벌 결제내역/진행상황 :<router-link
          :to="{ name: 'OrderFestivalPayment' }"
          class="count_link"
          >{{ festival.totalItems }}</router-link
        >건
      </h3>
      <base-table
        :loading="false"
        :isEdit="false"
        :isSort="false"
        :pageSize="pageSize"
        :fields="festival.fields"
        :items="festival.items"
      />
    </div>
    <div class="tbl_middle_box">
      <h3 class="tbl_count_title">
        전 일 스탬프 요청 :<router-link
          :to="{ name: 'SystemStampRequest' }"
          class="count_link"
          >{{ stampRequest.totalItems }}</router-link
        >건
      </h3>
      <base-table
        :loading="false"
        :isEdit="false"
        :isSort="false"
        :pageSize="pageSize"
        :fields="stampRequest.fields"
        :items="stampRequest.items"
      />
    </div>
    <div class="tbl_middle_box">
      <h3 class="tbl_count_title">
        전 일 일반 미션 완성 수 :
        <router-link
          :to="{ name: 'MissionMissionCompletePresentGeneral' }"
          class="count_link"
          >{{ mission.totalItems }}</router-link
        >건
      </h3>
      <base-table
        :loading="false"
        :isEdit="false"
        :isSort="false"
        :pageSize="pageSize"
        :fields="mission.fields"
        :items="mission.items"
      />
    </div>
    <div class="tbl_middle_box">
      <h3 class="tbl_count_title">
        전 일 배지 미션 완성 수 :
        <router-link
          :to="{ name: 'MissionMissionCompletePresentReward' }"
          class="count_link"
          >{{ missionReward.totalItems }}</router-link
        >건
      </h3>
      <base-table
        :loading="false"
        :isEdit="false"
        :isSort="false"
        :pageSize="pageSize"
        :fields="missionReward.fields"
        :items="missionReward.items"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";
import moment from "moment";
export default {
  name: "Dashboard",
  components: {},
  data() {
    /**
     * data
     */
    return {
      totalUserCnt: {},
      totalClientCnt: {},
      report: {
        items: [],
        fields: [
          {
            key: "user.name",
            width: "100px",
            label: "작성자 명",
          },
          {
            key: "user.email",
            width: "110px",
            label: "이메일",
          },
          {
            key: "type",
            width: "145px",
            label: "구분",
          },
          {
            key: "content",
            width: "150px",
            label: "상세내용",
            oneText: true,
          },
          {
            key: "registerDate",
            width: "150px",
            label: "작성 일시",
            formatter: {
              name: "date",
              type: "long",
            },
          },
          {
            key: "status",
            width: "100px",
            label: "상태",
          },
        ],
      },
      inquiry: {
        items: [],
        fields: [
          {
            key: "type",
            width: "100px",
            label: "구분",
            useClick: true,
          },
          {
            key: "status",
            width: "100px",
            label: "상태",
            useClick: true,
          },
          {
            key: "name",
            width: "100px",
            label: "담당자 명",
            useClick: true,
          },
          {
            key: "tel",
            width: "200px",
            label: "연락처",
            useClick: true,
          },
          {
            key: "email",
            width: "200px",
            label: "이메일",
            useClick: true,
          },
          {
            key: "content",
            width: "200px",
            label: "내용",
            oneText: true,
          },
          {
            key: "company",
            width: "200px",
            label: "회사(기관명)",
            useClick: true,
          },
          {
            key: "registerDate",
            width: "140px",
            label: "작성일시",
            formatter: {
              name: "date",
              type: "long",
            },
          },
        ],
      },
      festival: {
        items: [],
        fields: [
          {
            key: "registerDate",
            width: "150px",
            label: "결제일",
            formatter: {
              name: "date",
              type: "short",
            },
          },
          {
            key: "user.name",
            width: "140px",
            label: "회원명",
            useClick: true,
          },
          {
            key: "user.email",
            width: "200px",
            label: "이메일",
            useClick: true,
          },
          {
            key: "product.name",
            width: "120px",
            label: "상품명",
            useClick: true,
          },
          {
            key: "reference.festivalName",
            width: "200px",
            label: "축제명",
          },
          {
            key: "reference.festivalStartDate",
            width: "200px",
            label: "축제기간",
          },
          {
            key: "user.name",
            width: "140px",
            label: "구매자",
          },
          {
            key: "status",
            width: "140px",
            label: "진행상태",
          },
          {
            key: "optionData.operatingCount",
            width: "100px",
            label: "운영개수",
            clickedNum: 0,
            // formatter: {
            //   name: "number",
            // },
          },
          {
            key: "optionData.operatingDay",
            width: "100px",
            label: "운영일수",
            clickedNum: 0,
            formatter: {
              name: "number",
            },
          },
          {
            key: "total",
            width: "100px",
            label: "금액",
            formatter: {
              name: "number",
            },
          },
          {
            key: "pgType",
            width: "100px",
            label: "결제방법",
          },
        ],
      },
      stampRequest: {
        items: [],
        fields: [
          {
            key: "cover",
            width: "100px",
            label: "커버 이미지",
            tag: "img",
            useClick: true,
          },
          {
            key: "user.loginid",
            width: "100px",
            label: "아이디",
            useClick: true,
            useSort: true,
            clickedNum: 0,
          },
          {
            key: "user.name",
            width: "100px",
            label: "이름",
            useClick: true,
            useSort: true,
            clickedNum: 0,
          },
          {
            key: "name",
            width: "120px",
            label: "스탬프명",
            useClick: true,
          },
          {
            key: "address",
            width: "200px",
            label: "스탬프주소",
          },
          {
            key: "registerDate",
            width: "150px",
            label: "등록일시",
            formatter: {
              name: "date",
              type: "long",
            },
            useClick: true,
            useSort: true,
            clickedNum: 0,
          },
          // {
          //   key: "admin",
          //   width: "120px",
          //   label: "처리담당자",
          //   formatter: {
          //     name: "date",
          //     type: "long",
          //   },
          //   useClick: true,
          // },
          {
            key: "status",
            width: "100px",
            label: "상태",
            useClick: true,
          },
        ],
      },
      mission: {
        items: [],
        fields: [
          {
            key: "name",
            width: "100px",
            label: "회원 명",
          },
          {
            key: "mission.name",
            width: "100px",
            label: "미션 명",
          },
          {
            key: "receive",
            width: "100px",
            label: "리워드 수령방법",
          },
        ],
      },
      missionReward: {
        items: [],
        fields: [
          {
            key: "name",
            width: "100px",
            label: "회원 명",
          },
          {
            key: "mission.name",
            width: "100px",
            label: "미션 명",
          },
        ],
      },
      unregister: {
        items: [],
        fields: [
          {
            key: "name",
            width: "100px",
            label: "이름",
          },
          {
            key: "id",
            width: "100px",
            label: "아이디",
          },
          {
            key: "withdrawalDate",
            width: "100px",
            label: "탈퇴 일시",
          },
        ],
      },
      items: [],
      pageSize: 10,
      contentLoading: true,
      startDate: "",
      endDate: "",
    };
  },
  created() {
    /**
     * created
     */
    this.$Progress.start();

    this.fetch();
  },
  mounted() {
    /**
     * mounted
     */
    this.$Progress.finish();
  },
  computed: {
    /**
     * computed
     */
    ...mapGetters(["isAuthenticated"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    /*
    onSortClick(value) {
      this.currentSort = value.item.key;
      if (this.currentSort.includes(".")) {
        const sortString = this.currentSort.split(".");
        this.currentSort =
          sortString[0] +
          sortString[1][0].toUpperCase() +
          sortString[1].slice(1);
      }
      console.log(this.currentSort);
      this.fetch(1);
    },
*/
    async fetch() {
      this.startDate = moment(new Date()).add(-1, "day").format("YYYY-MM-DD");
      this.endDate = moment(new Date()).format("YYYY-MM-DD");

      let params = {
        size: this.pageSize,
        startDate: this.startDate,
        endDate: this.endDate,
      };

      await client.reportFindAll(params).then(
        (response) => {
          const { data } = response;
          data.items.forEach((item) => {
            if (item.status == 1) {
              item.status = "접수";
            } else if (item.status == 2) {
              item.status = "처리중";
            } else {
              item.status = "완료";
            }
          });
          console.log(data);
          this.report.items = data.items;
          this.report.totalItems = data.totalItems;
          this.report.totalPages = data.totalPages;
          this.report.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.report.items = [];
          this.report.totalItems = 0;
          this.report.totalPages = 0;
          this.report.contentLoading = false;
        }
      );
      await client.inquiryFindAll(params).then(
        (response) => {
          const { data } = response;
          data.items.forEach((item) => {
            if (item.status == 1) {
              item.status = "접수";
            } else if (item.status == 2) {
              item.status = "처리중";
            } else {
              item.status = "완료";
            }
          });
          this.inquiry.items = data.items;
          this.inquiry.totalItems = data.totalItems;
          this.inquiry.totalPages = data.totalPages;
          this.inquiry.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.inquiry.items = [];
          this.inquiry.totalItems = 0;
          this.inquiry.totalPages = 0;
          this.inquiry.contentLoading = false;
        }
      );
      await client.orderFindAll(params).then(
        (response) => {
          const { data } = response;
          this.festival.items = data.items;
          this.festival.totalItems = data.totalItems;
          this.festival.totalPages = data.totalPages;
          this.festival.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.festival.items = [];
          this.festival.totalItems = 0;
          this.festival.totalPages = 0;
          this.festival.contentLoading = false;
        }
      );
      await client.stampRequestFindAll(params).then(
        (response) => {
          const { data } = response;
          this.stampRequest.items = data.items;
          this.stampRequest.totalItems = data.totalItems;
          this.stampRequest.totalPages = data.totalPages;
          this.stampRequest.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.stampRequest.items = [];
          this.stampRequest.totalItems = 0;
          this.stampRequest.totalPages = 0;
          this.stampRequest.contentLoading = false;
        }
      );
      await client.missionCompleteGeneralFindAll(params).then(
        (response) => {
          const { data } = response;
          data.items.forEach((item) => {
            if (item.receive == 1) {
              item.receive = "배송수령";
            } else if (item.receive == 2) {
              item.receive = "직접수령";
            }

            if (item.tool == 0 || item.tool == 1) {
              item.tool = "GPS";
            } else if (item.tool == 2) {
              item.tool = "NFC";
            } else if (item.tool == 3) {
              item.tool = "Bluetooth";
            } else if (item.tool == 4) {
              item.tool = "QR 코드";
            } else if (item.tool == 5) {
              item.tool = "WIFI";
            } else if (item.tool == 6) {
              item.tool = "AR";
            }
          });
          this.mission.items = data.items;
          this.mission.totalItems = data.totalItems;
          this.mission.totalPages = data.totalPages;
          this.mission.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.mission.items = [];
          this.mission.totalItems = 0;
          this.mission.totalPages = 0;
          this.mission.contentLoading = false;
        }
      );
      await client.missionCompleteRewardFindAll(params).then(
        (response) => {
          const { data } = response;
          data.items.forEach((item) => {
            if (item.tool == 0 || item.tool == 1) {
              item.tool = "GPS";
            } else if (item.tool == 2) {
              item.tool = "NFC";
            } else if (item.tool == 3) {
              item.tool = "Bluetooth";
            } else if (item.tool == 4) {
              item.tool = "QR 코드";
            } else if (item.tool == 5) {
              item.tool = "WIFI";
            } else if (item.tool == 6) {
              item.tool = "AR";
            }
          });
          this.missionReward.items = data.items;
          this.missionReward.totalItems = data.totalItems;
          this.missionReward.totalPages = data.totalPages;
          this.missionReward.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.missionReward.items = [];
          this.missionReward.totalItems = 0;
          this.missionReward.totalPages = 0;
          this.missionReward.contentLoading = false;
        }
      );
      await client.generalUnregisterFindAll(params).then(
        (response) => {
          const { data } = response;
          this.unregister.items = data.items;
          this.unregister.totalItems = data.totalItems;
          this.unregister.totalPages = data.totalPages;
          this.unregister.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.unregister.items = [];
          this.unregister.totalItems = 0;
          this.unregister.totalPages = 0;
          this.unregister.contentLoading = false;
        }
      );
      await client.generalUserRegisterDaily(params).then(
        (response) => {
          const { data } = response;
          this.totalUserCnt = data.totalCnt;
        },
        () => {}
      );

      await client.clientRegisterDaily(params).then(
        (response) => {
          const { data } = response;
          this.totalClientCnt = data.totalCnt;
        },
        () => {}
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
